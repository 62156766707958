<template>
  <img
    src="@/assets/images/tamilcoin.png"
    alt="TamilCryptoCoin"
    style="width: 200px"
  />

  <h3 class="mt-4">தமிழ் மெய்நிகர் நாணயம்</h3>
  <h4>TAMIL Crypto</h4>

  <div class="row">
    <!--Col 1-->
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
      <p class="lead">உலகத் தமிழ் சொந்தங்களே,தமிழ் மொழி ஆர்வலர்களே</p>

      <div style="text-align: justify; text-justify: inter-word">
        <p>
          செம்மொழிகளுள் ஒன்றாகிய தமிழ்மொழி, எக்காலத்திற்கும் உகந்த மொழியாக
          வளர்ந்துள்ளது. இதே தனித் தன்மையில், எதிர்காலத்திலும் தொடர்ந்து
          வளர்ந்து, சிறப்பும் செழிப்பும் நிறைந்த மொழியாகத் திகழ வேண்டும்
          என்பதையும் கருத்தில் கொண்டு தமிழ் மொழியின் மேம்பாட்டிற்கும்
          வளர்ச்சிக்கும் உறுதுணையாக விளங்கி, சிறப்பாகச் செயல்படும் தமிழ்
          அமைப்புகளையும், தனிநபர்களையும், தமிழ் ஆர்வலர்களையும் ஒன்று
          திரட்டி,மொழி வளர்ச்சிக்கு வலுவூட்டும் நடவடிக்கைகளைத் தமிழ்ச்
          சமூகத்துடன் ஒன்றிணைந்து தமிழ் மொழியை கட்டச்சங்கிலி (Blockchain)
          தொழினுட்பத்திலும் இடம் பெற்று மிளிரச் செய்வதே TamilToken.org
          செயல்திட்டமாகும்.
        </p>

        <h5>தமிழ் மெய் நிகர் நாணயம் (Tamil Crypto Token) ஓர் அறிமுகம்</h5>

        <p>
          இந்த செயல்திட்டம் மூலமாக தமிழ் மொழி அதன் வளர்சிக்கு
          கல்லேடு/பேரேடு/கட்டச்சங்கிலி (blockchain) தொழினுட்பம் பயன்படுத்தும்
          முதல் மொழி என்ற அங்கீகாரம் பெறும்.
        </p>

        <p>
          தமிழ் மெய் நிகர் நாணய செயல்திட்டம் உலகத்தமிழர்கள் அனைவரையும் உள்வாங்கி
          பங்குதாரர் ஆக்கி தமிழ் மொழி விருத்திக்கு அனைவரினதும் பங்களிப்பை
          பல்வேறு செயல்திட்டங்கள் மூலம் சாத்தியப்படுத்தும் ஒரு கூட்டு முயற்சி.
        </p>

        <img
          src="@/assets/images/private-sale-tamil-table.png"
          alt="TamilCrypto-Private-Sale-Coin"
          style="width: 100%"
        />

        <p>
          உலகத் தமிழ் மக்கள் தொகை அடிப்படையில், ஒருவருக்கு 1 தமிழ் நாணயம் என்ற
          அடிப்படையில் 80 மில்லியன் TAMIL Crypto (தமிழ் மெய் நிகர் நாணயம்)
          வெளியிடப்பட்டுள்ளது. இதன் பெறுமதி ஏற்றமும் , இறக்கமும் இந்த வலையமைப்பு
          சார்ந்து இயக்கும் செயல்திட்டங்களுக்கு உலகத் தமிழர்களின் பங்களிப்பு
          மற்றும் ஆதரவு நிலைப்பாட்டிலேயே தங்கியுள்ளது.
        </p>

        <h5>TAMIL Crypto முக்கிய செயல்திட்டம் : தமிழ் தகவல் பரவலாக்கம்</h5>

        <h5 class="text-center">
          தகவல் பரவலாக்கம் என்றால் என்ன ? <br />
          (What is data decentralization?)
        </h5>

        <p>
          மின் மயப்படுத்தப்பட்ட தகவல்களை தனி நபரின் கணினியிலோ அல்லது
          அமைப்புகளின் கணினியிலோ வைக்காமல், தமிழ் மொழி ஆர்வலர் அனைவரும் ஒரு
          பிரதியை பதிவேற்றி வைக்கக்கூடிய தொழினுட்பம் மூலமாக தகல் சேமிப்பை
          பரவலாக்குதல்.
        </p>

        <img
          src="@/assets/images/data-decentralization-privatesale.png"
          alt="Data-Decentralization-TamilCrypto-Private-Sale-Coin"
          style="width: 100%"
        />
        <p>
          உதாரணத்துக்கு, ஆசியாவின் மிகப்பெரிய நூலகம் யாழ் நூலகம்
          எரிக்கப்பட்டதோடு, அதில் இருந்த எத்தனையோ லட்சம் அரிய தமிழ் நூல்கள்
          தீக்கிரையாக்கப்பட்டது. இது எமது இனத்திற்கு ஏற்பட்ட மீள முடியாத
          பேரிழப்பாகும்.
        </p>

        <p>இதை எவ்வாறு தடுத்திருக்கலாம்?</p>

        <p>
          அரிய நூல்கள் மற்றும் ஆவணங்களை ஒரே இடத்தில் பாதுகாத்து வைத்ததே இதற்கு
          முதல் காரணம், மின்னணு மயப்படுத்தி வேறிடத்தில் பதிவேற்றம் செய்து
          வைத்திருந்தால் ஓரளவு பாதுகாத்து இருக்கலாம்.
        </p>

        <p>
          சரி இப்பொழுது தான் மின் நூல்கள் மற்றும் மின் மயப்படுத்தப்பட்ட ஆவணங்கள்
          இருக்கின்றனவே, ஆம் இருக்கின்றன! ஆனால், இப்பொழுதும் தகவல் எல்லாம் ஒரு
          அமைப்பின் வழங்கல் கணினியிலோ அல்லது தனிநபர்களின் கணினியிலோதான்
          பதிவேற்றம் செய்யப்பட்டு இருக்கின்றது. பதிவேற்றப்பட்ட வழங்கல் கணினி
          (Server) தொழில்நுட்ப கோளாறு காரணமாக செயலிழந்தாலோ அல்லது பதிவேற்றப்பட்ட
          தகவல்கள் திட்டமிட்டு அழிக்கப் பட்டாலோ அல்லது மூலப்பிரதி மாற்றப்பட்டு
          போகவோ வாய்ப்பு இருக்கிறது. இதற்கெல்லாம் மூல காரணம் தகவல்
          மையப்படுத்தப்படுவதே (ஒரே இடத்தில் குவிக்கப்படுவதே).
        </p>

        <p>
          இன்னும் நாம் தமிழ் ஓலைச்சுவடி திருட்டு என்று தினமும் கேள்வி பட்டு
          கொண்டு தான் இருக்கின்றோம். இதை தடுக்க என்னவழி? ஒரே வழி தகவல்
          பரவலாக்கம்.
        </p>

        <p>
          கல்லேடு/பேரேடு தொழில்நுட்பம் மூலமாக நாம் தகவலை பரவலாக்கி (தமிழ் மின்
          ஆவணங்கள், இந்த செயல்திட்டத்தில் பங்குபெரும் உறுப்பினர்களின் கணினியில்
          பதிவேற்றப்பட்டு பாதுகாக்கப்படும்) இந்த இழப்புகளை தவிர்க்கலாம். எமது
          Tamil Crypto செயல்திட்டம் இதை மையப்படுத்தியே உருவாக்கப்பட்டது.
        </p>

        <h5>ஒரு மொழிக்காக ஏன் இவ்வளவு சிரமப்பாடு?</h5>

        <p>
          மொழியின் நீட்சியே இனத்தின் நீட்சியாகும். ஒரு மொழி அழிவடையும் போது இனம்
          அதன் அடையாளத்தை இழக்கின்றது. மற்றைய மொழிகள் போலல்லா. எமது அன்னைத்தமிழ்
          பல சிறப்பம்சங்களை தன்னகத்தே கொண்டுள்ளது.
        </p>

        <p>
          தீ, கடல் , எதிரிகள் துரோகிகள் அழித்தது போக இன்னும் மிச்சம் இருக்கும்
          எமது இனத்தின் சொத்துக்களை கணினிமயப்படுத்தி, தகவலை பரவலாக்கி அடுத்த
          தலைமுறைக்கு கடத்துவதே எமது நோக்கம்.
        </p>

        <p>மொழி வாழ்ந்தால் தான் இனம் வாழும்.</p>

        <p>நமது மொழிக் கடன்</p>

        <p>
          நமது மொழி காலம் காலமாக தனது தனித்தன்மையாலும் பலரது தியாகத்தாலும் தன்னை
          தானே புதுப்பித்துக்கொண்டு டிஜிட்டல் உலகிலும் முன்னணி மொழியாக வலம்
          வருகின்றது. இந்த தன்னிகரில்லாத தமிழ் மொழி வளர்சிக்கு நாம் என்ன செய்து
          இருக்கின்றோம் என்ற கேள்வி எல்லா தமிழர்களிடத்திலும் வர வேண்டும், இதுவே
          எமது மொழியை அடுத்த தலைமுறைக்கு வீரியத்துடன் எடுத்து செல்ல உதவும்.
          டிஜிட்டல் தளத்தில், கட்டச்சங்கிலி தொழில்நுட்பத்தின் ஊடாக இதை நாங்கள்
          ஒவ்வொருவரும் சாதிக்க முடியும். இதற்க்காகத்தான் TAMIL Crypto இயங்குதளம்
          உருவாக்கப்பட்டது.
        </p>

        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item"
            src="https://www.youtube.com/embed/Ff8rNIn2474?rel=0"
            allowfullscreen
          ></iframe>
        </div>

        <br />

        <h5>இந்த செயல்திட்டத்தில் நீங்கள் எப்படி பங்குபெறுவது.</h5>

        <p>
          நீங்கள் TamilToken.org இல் கணக்கு திறந்து செயல்திட்டங்களை
          பார்வையிடலாம் மற்றும் பங்குபெறலாம்.
        </p>

        <h5>Tamil Crypto Token எப்படி வாங்குவது.</h5>

        <p>
          https://stellarterm.com அல்லது https://interstellar.exchange போன்ற
          மெய் நிகர் நாணய சந்தைகளில் (Crypto Exchanges) வாங்கலாம்.
        </p>

        <h5>TAMIL Crypto வாங்கும் முறை</h5>

        <p>
          Interstellar exchange =>
          <a
            href="https://tamiltoken.org/tamiltoken-purchase-steps-interstellar-exchange.pdf"
            target="_blank"
          >
            https://stellarterm.com
          </a>
        </p>

        <p>
          Stellarterm exchange =>
          <a
            href="https://tamiltoken.org/tamiltoken-purchase-steps-stellarterm-exchange.pdf"
            target="_blank"
          >
            Stellarterm</a
          >
        </p>

        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item"
            src="https://www.youtube.com/embed/VTdsmTQ-FJw?rel=0"
            allowfullscreen
          ></iframe>
        </div>

        <br />

        <p>
          தனியார் விற்பனை நேரத்த்தில் வாங்கும் பொது ஆரம்பகால ஆதரவாளர்கள்
          என்றவகையில், உங்களுக்கு இனாமாக மேலதிக தமிழ் மெய் நிகர் நாணயங்கள்
          (TAMIL Crypto) கிடைக்கும்.
        </p>

        <h4>செயல்திட்ட நிர்வாகம் (Governance)</h4>

        <p>
          TAMIL Crypto வைத்திருப்பவர்கள், வாக்கு செலுத்தும் தகுதி பெறுவார்கள்.
          ஒரு செயல்திட்டம் ஆரம்பிக்கும் பொது நீங்கள் TAMIL Crypto மூலமாக ஆதரவு
          செலுத்தலாம் மற்றும் நீங்கள் குறைந்தது 10 TAMIL Crypto வைத்திருந்தால்
          நீங்கள் சமூக செயல்திட்டங்களுக்கு வாக்களிக்கும் உரிமை பெறுவீர்கள்.
        </p>

        <h5>ஆக்கப்பூர்வமான கருத்துக்களை உள்வாங்கல்</h5>

        <p>
          வெளிப்படைத்தன்மை மற்றும் சமூகக்கருத்துக்களை உள்வாங்குதல் என்பன இந்த
          TAMIL Crypto செயல்திட்டத்தை விரிவுப்படுத்த உதவும். பதிவு செய்யப்பட்ட
          உறுப்பினர்கள் யாவரும், தங்கள் ஆக்கப்பூர்வமான கருத்துக்களை
          தெரிவிக்கலாம். இந்த பின்னூட்டங்கள்/பதிவுகள் சமூக வாக்கெடுப்புக்கு
          விடப்பட்டு. 80% மான TAMIL Crypto சமூக வாக்குகள் சேர்ந்தபின்
          நடைமுறைப்படுத்தப்படும்.
        </p>

        <hr />

        <p>
          தந்தி (Telegram Channel) =>
          <a href="https://t.me/tamiltoken">https://t.me/tamiltoken</a>
        </p>
        <p>
          Twitter =>
          <a href="https://twitter.com/tamiltoken"
            >https://twitter.me/tamiltoken</a
          >
        </p>

        <hr />

        <a href="/whitepaper.pdf" target="_blank"> Download Whitepaper </a>

        <br /><br />
      </div>
    </div>
    <!--Col 1-->

    <!--Col 2-->
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
      <h5>Introduction to TAMIL Crypto</h5>

      <p>
        The TAMIL Crypto (TAMIL) is a blockchain-based system for rewarding
        community members for activities involved in preserving and developing
        the world’s oldest living classing language. Built on Stellar, its goal
        is to efficiently distribute TAMIL Cryptos between community projects,
        decentralized data node operators, and content publishers.
      </p>

      <h5>
        What actually promoted us to develop first crypto based ecosystem for
        language.
      </h5>

      <p>
        According to statistics by United Nations(UN); “Every two weeks, a
        language disappears taking with it an entire cultural and intellectual
        heritage. At least 43% of the estimated 6000 languages spoken in the
        world are endangered.” This shocking data motivated us to do something
        that can help to save languages from extinction.
      </p>

      <h5>Key Project of TAMIL Crypto Token</h5>

      <p>Why is data decentralisation very important for the Tamil language?</p>

      <p>
        Asia’s biggest library, Jaffna Library, which contained a lot of rare
        tamil books was burnt and destroyed. This was a huge irrecoverable loss
        for Tamil. This could have been easily prevented.
      </p>

      <p>
        The centralisation of all sources and inability to digitalise the
        sources led to the irrecoverable loss. Even Though there was limitation
        to technology during the period, copies of the books/sources kept at
        various locations could have reduced the impact of the loss.
      </p>

      <p>
        We now have a digital version of Tamil language assets (Documents ,
        manuscripts ,etc ..) , however, the information is stored at a
        centralised servers of private/govt organizations or on servers operated
        by individual users.
      </p>

      <p>
        We are still getting news daily on the theft of Tamil palm leaf
        manuscripts. How do we stop this? One way is via data dissemination.
      </p>

      <p>
        We need to preserve the remaining assets by decentralising the
        digitalised assets.
      </p>

      <p>
        It is our duty to pass on these assets to our next generation in their
        original form. Our language has been preserved due to the years of
        sacrifices by numerous individuals and thus, we have been able to hold
        the leading position in the digital world. Every Tamilian should ask the
        question of what are we doing and this will allow us to bring the
        language to the next generation successfully. We can achieve this by
        utilising Blockchain in the digital world. This is the reason why Tamil
        Token was created.
      </p>

      <p>
        We need to preserve the remaining assets by decentralising the
        digitalised assets. It is our duty to pass on these assets to our next
        generation in their original form. Our language has been preserved due
        to the years of sacrifices by numerous individuals and thus, we have
        been able to hold the leading position in the digital world. Every
        Tamilian should ask the question of what are we doing and this will
        allow us to bring the language to the next generation successfully. We
        can achieve this by utilising Blockchain in the digital world. This is
        the reason why TAMIL Crypto was created.
      </p>
      <div class="p-5 text-center">
        <h4 class="mb-3">Mission</h4>
        <p class="lead">
          Our current mission is to build, support and empower World Wide Tamil
          Community and Tamil Language through Crypto Token Ecosystem to fulfill
          the basic needs of each Tamil member.
        </p>

        <h4 class="mb-3">Vision</h4>
        <p class="lead">
          Empowering Tamil Language and Tamil Tech startups to grow further than
          they could alone in digital space.
        </p>

        <h5>How to Participate?</h5>
        <p>
          You will be able to view the projects via
          https://tamiltoken.org/projects
        </p>

        <h5>How to purchase TAMIL Crypto?</h5>
        <p>
          You can purchase TAMIL Crypto via the Stellarterm exchange. During
          private sales, early investors will receive free TAMIL Crypto.
        </p>

        <h5>Governance</h5>
        <p>
          Voting rights are only available to those who have purchased atleast 1
          TAMIL Crypto. Those with TAMIL Crypto can show their appreciation to
          projects of their choice.
        </p>

        <h5>Constructive Criticism</h5>
        <p>
          Transparency and society based opinions will assist in the improvement
          of the TAMIL Crypto and its projects. Constructive criticism can be
          provided by members who have been registered with TAMIL Crypto
          platform. These criticisms will be subjected to voting and will be
          acted upon after receiving 80% votes.
        </p>

        <hr />

        <p>
          Telegram Channel/Group =>
          <a href="https://t.me/tamiltoken">https://t.me/tamiltoken</a>
        </p>
        <p>
          Twitter =>
          <a href="https://twitter.com/tamiltoken"
            >https://twitter.me/tamiltoken</a
          >
        </p>

        <hr />

        <a href="/whitepaper.pdf" target="_blank"> Download Whitepaper </a>
      </div>
    </div>
    <!--Col 2-->
  </div>
</template>

<script>
export default {
  name: "airdrop",
};
</script>

<style scoped>
.box {
  padding: 10px;
}
.txt-body {
  text-align: left;
  font-weight: bold;
}

.box-heading {
  border: 1px solid rgba(100, 87, 87, 0.678);
  text-align: left;
  border-radius: 25px;
}
.box-heading > span {
  position: absolute;
  background: white;
  height: 20px;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
  font-weight: bold;
}
</style>
